import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
    <Layout>
        <div className="wrapper wrapper--default" style={{textAlign: 'center'}}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn't exist... the sadness.</p>
        </div>
    </Layout>
)

export default NotFoundPage
